import React, {useState} from'react';
import { useNavigate } from 'react-router';
import './MobileMenu.css';

function MobileMenu(){

    return(
        <div style={{border: '1px solid black', textAlign: 'center'}}>
        
               <h1 style={{color: 'aqua'}}>Dustin<span style={{color: 'red'}}>.</span><span style={{color: 'violet'}}>Ink</span></h1>
            
            
        </div>
    )
}

export default MobileMenu;  